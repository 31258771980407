import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import Seo from "../components/seo"
import Layout from "../components/layout"
import { Container, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const ArticlePageTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }

  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} />

      <Container>
        <article
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <br></br>
            <div>
              <h1 itemProp="headline">{parse(post.title)}</h1>
              <p>{post.date}</p>
              <p>{post.modified}</p>
              <div>
                Category:{" "}
                {post.categories.nodes.map((category, index) => [
                  index > 0 && ", ",
                  <Link key={index} to={category.link}>
                    {category.name}
                  </Link>,
                ])}
              </div>
            </div>

            <div>
              <Image src={post.author?.node?.avatar?.sourceUrl}></Image>
            </div>

            {/* if we have a featured image for this post let's display it */}
            {featuredImage?.data && (
              <GatsbyImage
                image={featuredImage.data}
                alt={featuredImage.alt}
                style={{ marginBottom: 50 }}
              />
            )}
          </header>

          <br></br>

          {/* Proper way I guess??? */}
          <Container>
            {!!post.content && (
              <section itemProp="articleBody">{parse(post.content)}</section>
            )}
          </Container>


          {/* Dangerous style way  */}
          {/* <Container>
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
          </Container> */}

          <hr />

          <footer>
            <Container>
              <h4>Biography tentang penulis</h4>
              <p>{post.author?.node?.firstName} {post.author?.node?.lastName}</p>
              <p>{post.author?.node?.description} </p>
            </Container>
            {/* <hr></hr>
            <div>
              Tag:{" "}
              {post.tags.nodes.map((tag, index) => [
                index > 0 && ", ",
                <Link key={index} to={tag.link}>
                  {tag.name}
                </Link>,
              ])}
            </div> */}
            <hr></hr>
          </footer>
        </article>

        <nav className="blog-post-nav">

          <h4>previous or next article</h4>

          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.uri} rel="prev">
                  ← {parse(previous.title)}
                </Link>
              )}
            </li>

            <li>
              {next && (
                <Link to={next.uri} rel="next">
                  {parse(next.title)} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Container>

    </Layout>
  )
}

export default ArticlePageTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      modified(formatString: "MM-DD-YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      author {
        node {
          lastName
          firstName
          id
          avatar {
            url
          }
          description
        }
      }
      tags {
        nodes {
          name
          link
        }
      }
      categories {
        nodes {
          name
          link
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
